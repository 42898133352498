<template>
    <div>
        <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container-fluid py-8">
                <!--begin::Education-->
                <div class="d-flex flex-row">
                    <UserNav activePage="calendar" activeTab="1"></UserNav>
                    <div class="flex-row-fluid ml-lg-8">
                        <div>
                            <!--begin::Head-->
                            <div class="card card-custom gutter-b">
                                <!--begin::Body-->
                                <div class="card-body d-flex align-items-center justify-content-between flex-wrap py-3">
                                    <!--begin::Info-->
                                    <div class="container">
                                        <div>All events listed in Mountain Time</div>
                                        <div class="iframe-wrapper">
                                            <iframe ref="chartiframe" id="datawrapper-chart-8dfPN" src="https://calendar.zoho.com/zc/ui/embed/#calendar=e434a4c36cc761218a599a43148bec9e17c0b15941d60370e66816a58d5bf06a882dfd84c6e4fd6d168f0b6f73ee7324
&title=Ingomu%20Calendar
&type=1&language=en&timezone=America%2FDenver&showTitle=1&showTimezone=1&startingDayOfWeek=0&timeFormat=0
&view=week&showDetail=1&theme=2&showAttendee=0&showSwitchingViews=0&eventColorType=bold"
                                                style="width: 0; min-width: 100% !important;" :height="iframeHeight"
                                                frameBorder="0" scrolling="no"></iframe>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import UserNav from "@/view/layout/header/UserNav.vue";

const DELTAS = {
    "100": 481,
    "200": 427,
    "300": 400,
    "400": 400,
    "500": 400,
    "700": 400,
    "800": 400,
    "900": 400,
    "1000": 400,
};

export default {
    name: "calendar",
    components: {
        UserNav
    },
    data() {
        return {
            iframeHeight: '0px', // initial height
        };
    },
    computed: {
        // Necessary for initial iframe height
        iframeOffsetWidth() {
            return this.$refs['chartiframe'].offsetWidth;
        },
    },
    mounted() {
        // set initial iframe height
        this.iframeHeight = `{DELTAS[Math.min(1e3,Math.max(100*Math.floor(this.iframeOffsetWidth/100),100))]}px`;
        // setup event listener
        window.addEventListener('message', this.handleIframeResize);
        this.$store.commit("CHANGE_TABINDEX", { tabIndex: 0 });
        this.$store.commit("CHANGE_ACTIVETAB", { activeTab: 15 });
    },
    beforeDestroy() {
        // destroy event listener
        window.removeEventListener('message', this.handleIframeResize);
    },
    methods: {
        handleIframeResize(e) {
            for (var b in e.data['datawrapper-height']) {
                if ('8dfPN' === b) {
                    this.iframeHeight = `${e.data['datawrapper-height'][b]}px`;
                }
            }
        },
    },
}
</script>

<style scoped>
.container {
    height: 100%;
    text-align: center;
    padding: 1em;
    box-sizing: border-box;
}

img {
    max-width: 100%;
    max-height: 100%;
    display: inline-block;
}

.iframe-wrapper {
    position: relative;
    height: 0;
    padding-top: 56.25%;
    overflow: hidden;
}

.iframe-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>